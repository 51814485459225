// CatalogSharing.jsx
import React, { useState, useCallback, useRef } from 'react';
import { useAuth } from '../utils/authContext';
import { ShareIcon, CopyIcon, CheckIcon, Download, ImageIcon } from 'lucide-react';
import SharePopup from './SharePopup';
import SocialShareImage from './SocialShareImage';
import { toPng } from 'html-to-image';
import StoreCardPopup from './StoreCardPopup';

const VITE_PRICILOG_URL = import.meta.env.VITE_PRICILOG_URL;
const VITE_PRICILOG_URL_DISPLAY = import.meta.env.VITE_PRICILOG_URL_DISPLAY;

const CatalogSharing = () => {
    const { user, selectedCatalog } = useAuth();
    const [isCopied, setIsCopied] = useState(false);
    const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
    const [isStoreCardOpen, setIsStoreCardOpen] = useState(false);
    const [showSocialImage, setShowSocialImage] = useState(false);
    const socialImageRef = useRef(null);

    const catalogUrl = `${VITE_PRICILOG_URL}/${selectedCatalog?.catalogName}`;
    const catalogUrlDisplay = `${VITE_PRICILOG_URL_DISPLAY}/${selectedCatalog?.catalogName}`;

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(catalogUrl).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        });
    }, [catalogUrl]);

    const handleShare = useCallback(() => {
        setIsSharePopupOpen(true);
    }, []);



    return (
        <>
            <div className="bg-white rounded-lg shadow-lg p-6 h-fit w-[98%]">
                <h2 className="text-xl font-bold mb-4">Share Your Catalog</h2>
                <div className="bg-gray-100 rounded-lg p-3 mb-3">
                    <a
                        href={catalogUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-900 hover:text-[#191990] flex items-center text-sm"
                    >
                        {catalogUrlDisplay}
                    </a>

                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={handleShare}
                        className="flex items-center justify-center text-blue-600 bg-blue-100 rounded-lg h-fit py-2 px-2 text-sm">
                        <ShareIcon className="w-4 h-4 mr-1" />
                        Share Link
                    </button>
                    <button
                        onClick={handleCopy}
                        className="flex items-center justify-center text-blue-600 bg-blue-100 rounded-lg h-fit py-2 px-2 text-sm">
                        {isCopied ? (
                            <>
                                <CheckIcon className="w-4 h-4 mr-1" />
                                Copied
                            </>
                        ) : (
                            <>
                                <CopyIcon className="w-4 h-4 mr-1" />
                                Copy Link
                            </>
                        )}
                    </button>
                    <button onClick={() => setIsStoreCardOpen(true)}
                        className="flex items-center justify-center text-blue-600 bg-blue-100 rounded-lg h-fit py-2 px-2 text-sm">
                        <ImageIcon className="w-4 h-4 mr-1" />
                        Store Card
                    </button>
                </div >
            </div >
            <SharePopup
                isOpen={isSharePopupOpen}
                onClose={() => setIsSharePopupOpen(false)}
                catalogUrl={catalogUrl}
                catalogName={selectedCatalog?.name || "My Catalog"}
                vendorName={user?.name || "Vendor"}
                description={selectedCatalog?.description || "Check out my online store on Pricilog!"}
                logoUrl={'pricilog.svg'}
                vendorLogoUrl={selectedCatalog?.logo}
            />
            <StoreCardPopup
                isOpen={isStoreCardOpen}
                onClose={() => setIsStoreCardOpen(false)}
                storeName={selectedCatalog?.name || "My Catalog"}
                description={selectedCatalog?.description || "Check out my online store on Pricilog!"}
                logoUrl={selectedCatalog?.logo}
                storeUrl={catalogUrl}
            />
        </>
    );
};

export default React.memo(CatalogSharing);