import React, { useState } from 'react';
import { X } from 'lucide-react';
import SuccessMessagePopup from './SuccessMessagePopup'
import { useMutation } from '@apollo/client';
import { SUBMIT_FEEDBACK } from '../graphql/mutations';


const FeedbackPopup = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit({ rating, comment });
      setRating(0);
      setComment('');
    } catch (error) {
      console.error("Submission failed", error);
    }
  };


  const handleClose = () => {
    setRating(0);
    setComment('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 bg-gray-50 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-[#191970]">Your Feedback</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rating
            </label>
            <div className="flex flex-wrap gap-1">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => setRating(star)}
                  className={`text-2xl sm:text-3xl bg-gray-50 px-6 py-4 focus:outline-none ${star <= rating ? 'text-yellow-400' : 'text-gray-300'
                    }`}
                >
                  ★
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Comment
            </label>
            <textarea
              id="comment"
              placeholder="How was your experience with Pricilog? Let us know what you liked best, what could be improved, or if you encountered any issues."
              rows={3}
              className="w-full px-3 py-2 bg-white text-gray-700 border rounded-lg focus:outline-none focus:border-[#191970] text-sm sm:text-base"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-[#191970] text-white py-2 px-4 rounded-lg hover:bg-opacity-90 transition duration-200 text-sm sm:text-base"
          >
            Submit Feedback
          </button>
        </form>
      </div>
    </div>
  );
};



const FeedbackSystem = () => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState({ title: '', message: '' });

  const [submitFeedback, { loading, error }] = useMutation(SUBMIT_FEEDBACK);

  const handleFeedbackSubmit = async (feedbackData) => {
    try {
      const { data } = await submitFeedback({
        variables: { input: feedbackData },
      });

      setIsFeedbackOpen(false);
      setSuccessMessage({
        title: 'Thank You!',
        message: 'Your feedback has been successfully submitted.'
      });
      setIsSuccessOpen(true);

      console.log('Feedback submitted:', data.submitFeedback);
    } catch (err) {
      console.error('Error submitting feedback:', err);
      setSuccessMessage({
        title: 'Submission Error',
        message: 'There was an error submitting your feedback. Please try again.'
      });
      setIsSuccessOpen(true);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsFeedbackOpen(true)}
        className="bg-[#191970] text-white px-3 py-2 rounded "
      >
        Give Feedback
      </button>
      <FeedbackPopup
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
      <SuccessMessagePopup
        isOpen={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
        title={successMessage.title}
        message={successMessage.message}
      />
      {loading && <p>Submitting feedback...</p>}
      {error && <p>Error: {error.message}</p>}
    </>
  );
};

export default FeedbackSystem;
