import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';

const SocialShareImage = ({
    storeName,
    description = '',
    logoUrl = '',
    storeUrl,
    forDownload = false // New prop to handle download-specific rendering
}) => {

    const [imageStates, setImageStates] = useState({
        logo: { loaded: false, data: null },
        pricilog: { loaded: false, data: null }
    });

    useEffect(() => {
        const loadImage = async (url, desiredWidth = null) => {
            try {
                const response = await fetch(url);
                const blob = await response.blob();

                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        // Calculate dimensions maintaining aspect ratio
                        let finalWidth = desiredWidth || img.width;
                        let finalHeight = desiredWidth ?
                            (img.height * desiredWidth) / img.width :
                            img.height;

                        // Create a high-resolution canvas
                        const canvas = document.createElement('canvas');
                        canvas.width = finalWidth * 2;  // 2x for retina
                        canvas.height = finalHeight * 2;  // 2x for retina

                        const ctx = canvas.getContext('2d');

                        // Enable sharp image rendering
                        ctx.imageSmoothingEnabled = true;
                        ctx.imageSmoothingQuality = 'high';

                        // Draw at 2x size for retina quality
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                        // Convert to PNG with maximum quality
                        const base64Data = canvas.toDataURL('image/png', 1.0);
                        URL.revokeObjectURL(img.src);
                        resolve(base64Data);
                    };
                    img.src = URL.createObjectURL(blob);
                });
            } catch (error) {
                console.error('Error loading image:', error);
                return null;
            }
        };

        const loadAllImages = async () => {
            const newImageStates = { ...imageStates };

            // Load images in parallel with specific widths
            const promises = [];

            if (logoUrl) {
                promises.push(
                    loadImage(logoUrl, 128) // Main logo at 128px width
                        .then(data => {
                            if (data) newImageStates.logo = { loaded: true, data };
                        })
                );
            }

            // Load Pricilog logo at 100px width for better quality
            promises.push(
                loadImage('/pricilog-wordmark-w.png', 200)
                    .then(data => {
                        if (data) newImageStates.pricilog = { loaded: true, data };
                    })
            );

            await Promise.all(promises);
            setImageStates(newImageStates);
        };

        loadAllImages();
    }, [logoUrl]);

    // Use different styling approach for download version
    const glassStyles = forDownload ? {
        // Simulated glass effect for download
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
        boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    } : {
        // Real glass effect for preview
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(12px)',
    };

    const backgroundStyles = forDownload ? {
        // More pronounced gradient for download
        background: 'linear-gradient(135deg, #191970 0%, #1E3A8A 100%)',
    } : {
        background: 'linear-gradient(135deg, #191970 0%, #1E3A8A 100%)',
    };

    // iOS-safe shadow styles
    const iosSafeBoxShadow = forDownload ?
        'filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))' :
        'box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37)';


    const textStyle = {
        letterSpacing: '-0.01em',  // Subtle, consistent letter spacing
        textRendering: 'optimizeLegibility',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    };

    const storeUrlNoHttp = storeUrl.replace(/(^\w+:|^)\/\//, '');

    return (
        <div className="relative w-full aspect-square bg-gradient-to-br  from-[#191970] via-blue-900 to-blue-950 p-4  flex flex-col justify-between overflow-hidden">
            {/* Geometric Background Elements */}
            <div className="absolute inset-0 overflow-hidden">
                {/* Large circle - using relative units */}
                <div className="absolute -right-[10%] -top-[10%] w-[40%] h-[40%] border-[1px]  border-white/25 rounded-full" />
                <div className="absolute right-[5%] top-[5%] w-[20%] h-[20%] border-[1px] border-white/25 rounded-full" />

                {/* Squares */}
                <div className="absolute -left-[5%] top-1/3 w-[15%] h-[15%] border-[1px] border-white/25 rotate-45" />
                <div className="absolute left-[8%] top-1/2 w-[10%] h-[10%] border-[1px] border-white/25 rotate-12" />

                {/* Triangles - using relative units */}
                <div className="absolute bottom-[8%] -left-[5%] w-0 h-0 border-l-[3vw] border-l-transparent border-t-[6vw] border-t-white/25 border-r-[3vw] border-r-transparent rotate-12" />

                {/* Dots pattern - using relative units */}
                {/* <div className="absolute inset-0" style={{
                    backgroundImage: 'radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px)',
                    backgroundSize: '2% 2%'
                }} /> */}
            </div>

            {/* Glassmorphism Glow Effects */}
            <div className="absolute top-1/4 -left-[10%] w-[25%] h-[25%] rounded-full"
                style={{
                    background: 'radial-gradient(circle, rgba(147,197,253,0.3) 0%, rgba(147,197,253,0) 70%)'
                }}
            />
            <div className="absolute bottom-1/4 -right-[10%] w-[25%] h-[25%] rounded-full"
                style={{
                    background: 'radial-gradient(circle, rgba(216,180,254,0.3) 0%, rgba(216,180,254,0) 70%)'
                }}
            />
            {/* Main Content Container */}
            <div className="relative h-full rounded-2xl md:rounded-3xl p-4 md:p-6 border border-[rgba(255,255,255,0.2)] flex flex-col justify-between"
                style={glassStyles}
            >
                {/* Top Section */}
                <div className="flex flex-col items-center text-center">
                    {logoUrl && imageStates.logo.loaded ? (
                        <div className="w-16 h-16 rounded-xl overflow-hidden bg-white/20 border border-white/20 mb-1.5"
                            style={{ filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))' }}>
                            <img
                                src={imageStates.logo.data}
                                alt={`${storeName} logo`}
                                className="w-full h-full object-contain"
                                crossOrigin="anonymous"
                                style={{
                                    transform: 'translateZ(0)',
                                    backfaceVisibility: 'hidden',
                                    imageRendering: '-webkit-optimize-contrast',
                                    objectFit: 'contain'
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="w-16 h-16 rounded-xl bg-white/20 border border-white/20 mb-1.5 flex items-center justify-center"
                            style={{ filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))' }}>
                            <span className="text-2xl font-bold text-white">
                                {storeName.charAt(0)}
                            </span>
                        </div>
                    )}

                    <h1 className="text-xl font-bold text-white mb-1.5" style={textStyle}>
                        {storeName}
                    </h1>

                    {description && (
                        <p className="text-xs text-white/80 max-w-[90%] line-clamp-3 mb-2" style={textStyle}>
                            {description}
                        </p>
                    )}
                </div>

                {/* Middle Section - QR Code */}
                <div className="flex justify-center my-2">
                    <div className="bg-white/90 rounded-lg p-1.5 w-20 h-20"
                        style={{ filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))' }}>
                        {/* Placeholder for QR Code */}
                        <QRCodeSVG
                            value={storeUrl}
                            size={128}
                            bgColor={"#ffffff"}
                            fgColor={"#191970"}
                            level={"M"} // Increased error correction level
                            includeMargin={false}
                            style={{ width: '100%', height: 'auto' }}
                        />

                        {/* <div className="w-full h-full bg-[#191970] rounded-md" /> */}
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="text-center">
                    <p className="text-xs text-white/80 mb-1" style={textStyle}>
                        Scan to visit our store or go to
                    </p>
                    <p className="text-sm font-semibold text-white mb-3 break-all" style={{
                        ...textStyle,
                        wordSpacing: '0.1px',  // Subtle word spacing for URLs
                        fontFeatureSettings: '"kern" 1, "liga" 0',  // Disable ligatures for URLs
                    }}>
                        {storeUrlNoHttp}
                    </p>

                    {/* Pricilog Branding */}
                    <div className="flex items-center justify-center gap-2">
                        <span className="text-[9px] text-white/80 whitespace-nowrap text-center" style={textStyle}>
                            Created with
                        </span>
                        {imageStates.pricilog.loaded && (
                            <img
                                src={imageStates.pricilog.data}
                                alt="Pricilog"
                                className="h-4"
                                crossOrigin="anonymous"
                                style={{
                                    transform: 'translateZ(0)',
                                    backfaceVisibility: 'hidden',
                                    imageRendering: '-webkit-optimize-contrast',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialShareImage;