import React, { useRef } from 'react';
import { X, Download } from 'lucide-react';
import SocialShareImage from './SocialShareImage';
import { toPng } from 'html-to-image';

const StoreCardPopup = ({ isOpen, onClose, storeName, description, logoUrl, storeUrl }) => {
    const socialImageRef = useRef(null);

    if (!isOpen) return null;

    const downloadSocialImage = async () => {
        if (!socialImageRef.current) return;

        try {
            // Wait for all images to be fully loaded
            const images = socialImageRef.current.getElementsByTagName('img');
            await Promise.all([...images].map(img => {
                return new Promise((resolve) => {
                    if (img.complete) resolve();
                    else {
                        img.onload = resolve;
                        img.onerror = resolve;
                    }
                });
            }));

            // Multiple capture attempts for iOS Safari
            const captureOptions = {
                cacheBust: true,
                scale: 2,
                quality: 0.9,
                pixelRatio: 2,
                canvasWidth: 400,
                canvasHeight: 400,
                backgroundColor: '#ffffff',
                style: {
                    transform: 'scale(1)',
                    transformOrigin: 'top left'
                },
                filter: (node) => {
                    // Preserve image rendering quality
                    if (node instanceof HTMLImageElement) {
                        node.style.imageRendering = '-webkit-optimize-contrast';
                    }
                    return true;
                }
            };


            // Warm-up captures for iOS Safari
            await toPng(socialImageRef.current, captureOptions);
            await toPng(socialImageRef.current, captureOptions);
            await toPng(socialImageRef.current, captureOptions);
            await toPng(socialImageRef.current, captureOptions);


            // Final capture that should have images properly loaded
            const imageData = await toPng(socialImageRef.current, captureOptions);

            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

            if (isIOS) {
                const fullScreenImg = document.createElement('div');
                fullScreenImg.style.cssText = `
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    background: rgba(0,0,0,0.95);
                    z-index: 999999;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    -webkit-user-select: none;
                    user-select: none;
                    padding: 20px;
                `;

                // Container for image and press indicator
                const imageContainer = document.createElement('div');
                imageContainer.style.cssText = `
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: 90%;
                    max-height: 70vh;
                `;

                const img = document.createElement('img');
                img.src = imageData;
                img.style.cssText = `
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    -webkit-user-select: none;
                    user-select: none;
                    border-radius: 12px;
                    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
                     image-rendering: -webkit-optimize-contrast;
                `;

                // Press and hold indicator animation
                const indicator = document.createElement('div');
                indicator.style.cssText = `
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    border: 3px solid #ffffff;
                    opacity: 0;
                    animation: pulse 2s infinite;
                    pointer-events: none;
                `;

                // Add animation keyframes
                const style = document.createElement('style');
                style.textContent = `
                    @keyframes pulse {
                        0% {
                            transform: translate(-50%, -50%) scale(0.8);
                            opacity: 0;
                        }
                        50% {
                            opacity: 0.5;
                        }
                        100% {
                            transform: translate(-50%, -50%) scale(1.2);
                            opacity: 0;
                        }
                    }
                `;
                document.head.appendChild(style);

                // Instructions container
                const instructionsContainer = document.createElement('div');
                instructionsContainer.style.cssText = `
                    margin-top: 24px;
                    text-align: center;
                    font-family: -apple-system, system-ui;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                `;

                const mainText = document.createElement('p');
                mainText.textContent = 'Press and hold the image';
                mainText.style.cssText = `
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0;
                `;

                const subText = document.createElement('p');
                subText.textContent = 'When the menu appears, tap "Save to Photos"';
                subText.style.cssText = `
                    font-size: 14px;
                    opacity: 0.8;
                    margin: 0;
                `;

                instructionsContainer.appendChild(mainText);
                instructionsContainer.appendChild(subText);

                // Close button with tap area
                const closeBtn = document.createElement('button');
                closeBtn.innerHTML = '×';
                closeBtn.style.cssText = `
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 44px;
                    height: 44px;
                    color: white;
                    font-size: 32px;
                    background: rgba(255,255,255,0.1);
                    border: none;
                    border-radius: 22px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    line-height: 1;
                `;

                // Show animation on first tap
                let hasInteracted = false;
                imageContainer.onclick = () => {
                    if (!hasInteracted) {
                        indicator.style.opacity = '1';
                        hasInteracted = true;
                        setTimeout(() => {
                            indicator.style.opacity = '0';
                        }, 2000);
                    }
                };

                imageContainer.appendChild(img);
                imageContainer.appendChild(indicator);
                fullScreenImg.appendChild(imageContainer);
                fullScreenImg.appendChild(instructionsContainer);
                fullScreenImg.appendChild(closeBtn);
                document.body.appendChild(fullScreenImg);

                // Cleanup function that removes all created elements
                const cleanup = () => {
                    document.body.removeChild(fullScreenImg);
                    document.head.removeChild(style);
                };

                closeBtn.onclick = cleanup;
                fullScreenImg.onclick = (e) => {
                    if (e.target === fullScreenImg) cleanup();
                };
            } else {
                const link = document.createElement('a');
                link.download = `${storeName}-store-card.png`;
                link.href = imageData;
                link.click();
            }
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-xl font-bold text-[#191970]">Store Card</h4>
                    <button onClick={onClose} className="text-gray-500 bg-gray-100 hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>
                <div ref={socialImageRef} className="w-full">
                    <SocialShareImage
                        storeName={storeName}
                        description={description}
                        logoUrl={logoUrl}
                        storeUrl={storeUrl}
                        forDownload={true}
                    />
                </div>
                <button
                    onClick={downloadSocialImage}
                    className="mt-4 w-full flex items-center justify-center text-blue-600 bg-blue-100 rounded-lg py-2 px-4 text-sm"
                >
                    <Download size={16} className="mr-2" />
                    Download Store Card
                </button>
            </div>
        </div>
    );
};

export default StoreCardPopup;
