import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CATALOG } from '../graphql/mutations';
import { businessCategories, nigerianStates } from '../data/businessInfo';
import { useAuth } from '../utils/authContext';
import { BusinessSetupStepper } from './BusinessSetupStepper';
import { validatePhoneNumber } from '../utils/validateNumber'

const BusinessTypeStep = ({ formData, onChange, errors }) => (
    <div className="space-y-6">
        <div>
            <label className="block text-sm font-medium text-gray-700">
                Business Name*
            </label>
            <input
                type="text"
                name="name"
                maxLength={32}
                value={formData.name}
                onChange={onChange}
                className="mt-1 w-full px-3 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
            />
            {errors.name && (
                <p className="mt-1 text-sm text-red-600">{errors.name}</p>
            )}
        </div>

        <div>
            <label className="block text-sm font-medium text-gray-700">
                Business Description
            </label>
            <textarea
                name="description"
                value={formData.description}
                onChange={onChange}
                placeholder='What does your business do'
                rows="3"
                maxLength={300}
                className="mt-1 w-full px-3 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
            />
            <p className="mt-1 text-sm text-gray-500">
                {formData.description.length}/300 characters
            </p>
            {errors.description && (
                <p className="mt-1 text-sm text-red-600">{errors.description}</p>
            )}
        </div>

        <div>
            <label className="block text-sm font-medium text-gray-700">
                Business Type*
            </label>
            <select
                name="businessType"
                value={formData.businessType}
                onChange={onChange}
                className="mt-1 w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
            >
                <option value="">Select Business Type</option>
                <option value="PHYSICAL">Physical Store</option>
                <option value="ONLINE">Online Only</option>
                <option value="BOTH">Both Physical and Online</option>
            </select>
            {errors.businessType && (
                <p className="mt-1 text-sm text-red-600">{errors.businessType}</p>
            )}
        </div>

        {formData.businessType !== 'ONLINE' && (
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Location*
                </label>
                <select
                    name="location"
                    value={formData.location}
                    onChange={onChange}
                    className="mt-1 w-full px-3 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                >
                    <option value="">Select State</option>
                    {nigerianStates.map(state => (
                        <option key={state} value={state}>{state}</option>
                    ))}
                </select>
                {errors.location && (
                    <p className="mt-1 text-sm text-red-600">{errors.location}</p>
                )}
            </div>
        )}
    </div>
);

const CategoryStep = ({ formData, onChange }) => (
    <div className="space-y-6">
        <div>
            <label className="block text-sm font-medium text-gray-700">
                Business Category*
            </label>
            <select
                name="businessCategory"
                value={formData.businessCategory}
                onChange={onChange}
                className="mt-1 w-full px-3 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
            >
                <option value="">Select Category</option>
                {Object.keys(businessCategories).map(category => (
                    <option key={category} value={category}>{category}</option>
                ))}
            </select>
        </div>

        {/* {formData.businessCategory && (
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Sub Categories (Select all that apply)
                </label>
                <div className="grid grid-cols-2 gap-2">
                    {businessCategories[formData.businessCategory].map(subCat => (
                        <label key={subCat} className="inline-flex items-center">
                            <input
                                type="checkbox"
                                value={subCat}
                                checked={formData.subCategories.includes(subCat)}
                                onChange={onSubCategoryChange}
                                className="h-4 w-4 text-[#2424db] focus:ring-[#2424db] border-gray-300 rounded"
                            />
                            <span className="ml-2 text-sm text-gray-700">{subCat}</span>
                        </label>
                    ))}
                </div>
            </div>
        )} */}
    </div>
);

const ContactStep = ({ formData, onChange, errors }) => {
    const isPhoneValid = formData.phone ? validatePhoneNumber(formData.phone) : false;

    const handleCheckoutToggle = (e) => {
        if (!isPhoneValid && e.target.checked) {
            // Prevent enabling checkout without valid phone
            e.preventDefault();
            return;
        }
        onChange(e);
    };

    return (
        <div className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    WhatsApp Number
                </label>
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={onChange}
                    placeholder="+234"
                    className="mt-1 w-full px-3 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                />
                {errors.phone && (
                    <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
                )}
            </div>

            <div className="flex items-center">
                <input
                    type="checkbox"
                    name="allowCheckout"
                    checked={formData.allowCheckout}
                    onChange={handleCheckoutToggle}
                    disabled={!isPhoneValid}
                    className="h-4 w-4 text-[#2424db] focus:ring-[#2424db] border-gray-300 rounded disabled:opacity-50"
                />
                <label className={`ml-2 text-sm ${!isPhoneValid ? 'text-gray-400' : 'text-gray-700'}`}>
                    Enable WhatsApp Checkout
                </label>
                {!isPhoneValid && formData.allowCheckout && (
                    <p className="mt-1 text-sm text-red-600">
                        Valid WhatsApp number required for checkout
                    </p>
                )}
            </div>
        </div>
    );
};

const ReviewStep = ({ formData }) => (
    <div className="space-y-6">
        <h3 className="font-medium text-lg text-gray-900">Review Your Information</h3>

        <div className="bg-gray-50 p-4 rounded-lg space-y-4">
            <div>
                <h4 className="text-sm font-medium text-gray-500">Business Details</h4>
                <p className="mt-1">{formData.name}</p>
                <p className="mt-1 text-sm text-gray-600">{formData.description}</p>
            </div>

            <div>
                <h4 className="text-sm font-medium text-gray-500">Type & Location</h4>
                <p className="mt-1">{formData.businessType}</p>
                {formData.location && <p className="mt-1">{formData.location}</p>}
            </div>

            <div>
                <h4 className="text-sm font-medium text-gray-500">Category</h4>
                <p className="mt-1">{formData.businessCategory}</p>
                {/* {formData.subCategories.length > 0 && (
                    <div className="mt-1">
                        <span className="text-sm text-gray-500">Sub Categories: </span>
                        {formData.subCategories.join(', ')}
                    </div>
                )} */}
            </div>

            <div>
                <h4 className="text-sm font-medium text-gray-500">Contact</h4>
                <p className="mt-1">{formData.phone}</p>
                <p className="mt-1 text-sm">
                    WhatsApp Checkout: {formData.allowCheckout ? 'Enabled' : 'Disabled'}
                </p>
            </div>
        </div>
    </div>
);

const BusinessSetupForm = ({ onComplete }) => {
    const { selectedCatalog } = useAuth();
    const [updateCatalog] = useMutation(UPDATE_CATALOG);
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        businessType: selectedCatalog?.businessType || '',
        businessCategory: selectedCatalog?.businessCategory || '',
        subCategories: selectedCatalog?.subCategories || [],
        location: selectedCatalog?.location || '',
        name: selectedCatalog?.name || '',
        description: selectedCatalog?.description || '',
        phone: selectedCatalog?.phone || '',
        allowCheckout: selectedCatalog?.allowCheckout || false,
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFinish = async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        try {
            await updateCatalog({
                variables: {
                    id: selectedCatalog.id,
                    ...formData
                }
            });
            onComplete();
        } catch (error) {
            setErrors({ submit: error.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleInputChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: null }));
        }
    }, [errors]);

    // const handleSubCategoryChange = useCallback((e) => {
    //     const value = e.target.value;
    //     setFormData(prev => ({
    //         ...prev,
    //         subCategories: prev.subCategories.includes(value)
    //             ? prev.subCategories.filter(cat => cat !== value)
    //             : [...prev.subCategories, value]
    //     }));
    // }, []);

    const validateStep = useCallback(() => {
        const newErrors = {};

        switch (currentStep) {
            case 1:
                if (!formData.name) newErrors.name = 'Business name is required';
                if (!formData.businessType) newErrors.businessType = 'Business type is required';
                if (formData.businessType !== 'ONLINE' && !formData.location) {
                    newErrors.location = 'Location is required for physical businesses';
                }
                break;
            case 2:
                if (!formData.businessCategory) newErrors.businessCategory = 'Category is required';
                break;
            case 3:
                if (formData.phone && !/^\+?[0-9]\d{1,14}$/.test(formData.phone)) {
                    newErrors.phone = 'Please enter a valid phone number';
                }
                break;
            default:
                break;
        }

        return newErrors;
    }, [currentStep, formData]);

    const handleNext = useCallback(() => {
        const stepErrors = validateStep();
        if (Object.keys(stepErrors).length > 0) {
            setErrors(stepErrors);
            return;
        }
        setCurrentStep(prev => Math.min(prev + 1, 4));
    }, [validateStep]);

    const handleBack = useCallback(() => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;

        // Only proceed with submission if we're on the final step
        if (currentStep !== 4) {
            handleNext();
            return;
        }

        setIsSubmitting(true);
        try {
            await updateCatalog({
                variables: {
                    id: selectedCatalog.id,
                    ...formData
                }
            });

            // Only call onComplete after successful submission
            onComplete();

        } catch (error) {
            setErrors({ submit: error.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <BusinessSetupStepper currentStep={currentStep} />

            <div className="mt-8 space-y-6">
                {currentStep === 1 && (
                    <BusinessTypeStep
                        formData={formData}
                        onChange={handleInputChange}
                        errors={errors}
                    />
                )}

                {currentStep === 2 && (
                    <CategoryStep
                        formData={formData}
                        onChange={handleInputChange}
                    // onSubCategoryChange={handleSubCategoryChange}
                    />
                )}

                {currentStep === 3 && (
                    <ContactStep
                        formData={formData}
                        onChange={handleInputChange}
                        errors={errors}
                    />
                )}

                {currentStep === 4 && (
                    <ReviewStep formData={formData} />
                )}

                {errors.submit && (
                    <p className="text-sm text-red-600 text-center">{errors.submit}</p>
                )}

                <div className="flex justify-between pt-4">
                    <button
                        type="button"
                        onClick={handleBack}
                        disabled={currentStep === 1}
                        className="px-4 py-2 text-[#191970] border bg-white border-[#191970] rounded-md hover:bg-gray-50 disabled:opacity-50"
                    >
                        Back
                    </button>

                    {currentStep < 4 ? (
                        <button
                            type="button"
                            onClick={handleNext}
                            className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90"
                        >
                            Next
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={handleFinish}
                            disabled={isSubmitting}
                            className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90 disabled:opacity-50"
                        >
                            {isSubmitting ? 'Saving...' : 'Complete Setup'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusinessSetupForm;