import React, { useState, useRef } from 'react';
import { X, Mail, Facebook, Twitter, Instagram, Send, MessageCircle, QrCode, Copy, Check, Download, IdCard } from 'lucide-react';
import { FaWhatsapp } from 'react-icons/fa';
import QRCodeWithLogo from './QRCodeWithLogo';
import InstagramGuide from './InstagramGuide';
import TwitterGuide from './TwitterGuide';
import FacebookGuide from './FacebookGuide';
import { useEffect } from 'react';
import html2canvas from 'html2canvas';
import { toPng } from 'html-to-image';

const SharePopup = ({ isOpen, onClose, catalogUrl, catalogName, description, vendorName, logoUrl, vendorLogoUrl }) => {
    const [copiedText, setCopiedText] = useState('');
    const [showQRCode, setShowQRCode] = useState(false);
    const [currentGuide, setCurrentGuide] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setShowQRCode(false); // Reset to share options view when reopening
            setCurrentGuide(null);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const publishOptions = [
        { name: 'Instagram', icon: Instagram, action: () => setCurrentGuide('instagram') },
        { name: 'Twitter', icon: Twitter, action: () => setCurrentGuide('twitter') },
        { name: 'Facebook', icon: Facebook, action: () => setCurrentGuide('facebook') },
    ];


    const generateShareText = (platform) => {
        const baseText = `Check out ${catalogName} latest pricelist on Pricilog!`;
        const callToAction = "Find what you need at amazing prices.";


        switch (platform) {
            case 'email':
                return `${baseText}\n\n${callToAction}\n\nView the catalog here: ${catalogUrl}`;
            case 'facebook':
                return `${baseText} ${callToAction}\n\n${catalogUrl}\n\n#Pricilog #OnlineCatalog`;
            case 'twitter':
                return `${baseText} ${callToAction} #Pricilog #ShopLocal`;
            case 'whatsapp':
                return `${baseText} ${callToAction}\n\n${catalogUrl}`;
            case 'instagram':
                return `${baseText}\n\n${callToAction}\n\nLink in bio!\n\n#Pricilog #OnlineCatalog #ShopLocal`;
            case 'telegram':
                return `${baseText}\n\n${callToAction}\n\n${catalogUrl}`;
            case 'sms':
                return `${baseText} View it here: ${catalogUrl}`;
            default:
                return `${baseText} ${callToAction} ${catalogUrl}`;
        }
    };




    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedText(text);
            setTimeout(() => setCopiedText(''), 2000);
        });
    };

    const shareOptions = [
        {
            name: 'Email',
            icon: Mail,
            action: () => window.open(`mailto:?subject=${encodeURIComponent(`Check out ${vendorName}'s online store on Pricilog`)}&body=${encodeURIComponent(generateShareText('email'))}`)
        },
        {
            name: 'Facebook',
            icon: Facebook,
            action: () => {
                const text = generateShareText('facebook');
                copyToClipboard(text);
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(catalogUrl)}`, '_blank');
            }
        },
        {
            name: 'Twitter',
            icon: Twitter,
            action: () => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(generateShareText('twitter'))}&url=${encodeURIComponent(catalogUrl)}`)
        },
        {
            name: 'WhatsApp',
            icon: FaWhatsapp,
            action: () => window.open(`https://wa.me/?text=${encodeURIComponent(generateShareText('whatsapp'))}`)
        },
        {
            name: 'Telegram',
            icon: Send,
            action: () => window.open(`https://t.me/share/url?url=${encodeURIComponent(catalogUrl)}&text=${encodeURIComponent(generateShareText('telegram'))}`)
        },
        {
            name: 'SMS',
            icon: MessageCircle,
            action: () => window.open(`sms:?body=${encodeURIComponent(generateShareText('sms'))}`)
        },
        {
            name: 'QR Code',
            icon: QrCode,
            action: () => setShowQRCode(true)
        },
    ];

    const downloadQRCode = () => {
        const qrCodeElement = document.querySelector('#qr-code-container');
        if (!qrCodeElement) {
            console.error('QR Code container not found');
            return;
        }

        html2canvas(qrCodeElement, {
            scale: 4, // Increase scale for higher resolution
            useCORS: true, // Enable CORS for loading images
            logging: false, // Disable logging
            backgroundColor: null, // Transparent background
            imageTimeout: 0, // No timeout for rendering images
        }).then(canvas => {
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = `${catalogName}-QRCode.png`;
            downloadLink.href = pngFile;
            downloadLink.click();
        });
    };

    if (!isOpen) return null;

    const renderGuide = () => {
        switch (currentGuide) {
            case 'instagram':
                return <InstagramGuide onClose={() => setCurrentGuide(null)} catalogUrl={catalogUrl} vendorName={vendorName} />;
            case 'twitter':
                return <TwitterGuide onClose={() => setCurrentGuide(null)} catalogUrl={catalogUrl} vendorName={vendorName} />;
            case 'facebook':
                return <FacebookGuide onClose={() => setCurrentGuide(null)} catalogUrl={catalogUrl} vendorName={vendorName} />;
            default:
                return null;
        }
    };

    const renderContent = () => {
        if (currentGuide) {
            return renderGuide();
        }

        if (showQRCode) {
            return (
                <div className="flex flex-col items-center">
                    <QRCodeWithLogo url={catalogUrl} size={200} logoUrl={logoUrl} />
                    <button
                        onClick={downloadQRCode}
                        className="mt-4 flex items-center justify-center text-blue-600 bg-blue-100 rounded-lg py-2 px-4 text-sm"
                    >
                        <Download size={16} className="mr-2" />
                        Download QR Code
                    </button>
                    <button
                        onClick={() => setShowQRCode(false)}
                        className="mt-2 text-gray-600 hover:text-gray-800 bg-gray-100"
                    >
                        Back to sharing options
                    </button>
                </div>
            );
        }

        return (
            <>
                <div className="mb-6">
                    <h5 className="text-lg font-semibold mb-2">Publish your catalog</h5>
                    <div className="grid grid-cols-3 gap-4">
                        {publishOptions.map((option, index) => (
                            <button
                                key={index}
                                onClick={option.action}
                                className="flex flex-col items-center justify-center p-2 rounded-lg bg-gray-100"
                            >
                                <option.icon size={24} className="text-[#191970] mb-2" />
                                <span className="text-sm">{option.name}</span>
                            </button>
                        ))}
                    </div>
                </div>

                <div>
                    <h5 className="text-lg font-semibold mb-2">Share your catalog</h5>
                    <div className="grid grid-cols-3 gap-4">
                        {shareOptions.map((option, index) => (
                            <button
                                key={index}
                                onClick={option.action}
                                className="flex flex-col items-center justify-center p-2 rounded-lg bg-gray-100"
                            >
                                <option.icon size={24} className="text-[#191970] mb-2" />
                                <span className="text-sm">{option.name}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-xl font-bold text-[#191970]">Share Your Catalog</h4>
                    <button onClick={onClose} className="text-gray-500 bg-gray-100 hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>
                {renderContent()}
                {copiedText && (
                    <div className="mt-4 p-2 bg-green-100 text-green-800 rounded-lg flex items-center">
                        <Check size={20} className="mr-2" />
                        <span>Text copied! Paste it in your post for better engagement.</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SharePopup;
