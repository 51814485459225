import React, { useState } from 'react';
import { Info, X } from 'lucide-react';

const MobileTooltip = ({ content, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleTooltip = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative inline-block">
            <button
                onClick={toggleTooltip}
                className="p-1 rounded-full hover:bg-gray-100 active:bg-gray-200 transition-colors"
                aria-label="More information"
            >
                {children || <Info className="w-4 h-4 bg-gray-100 text-gray-500" />}
            </button>

            {isOpen && (
                <>
                    {/* Backdrop */}
                    <div
                        className="fixed inset-0 bg-black/20 z-40"
                        onClick={() => setIsOpen(false)}
                    />

                    {/* Tooltip Content */}
                    <div className="fixed bottom-0 left-0 right-0 bg-white p-4 rounded-t-xl shadow-lg z-50 animate-slide-up">
                        <div className="flex items-start justify-between mb-5">
                            <div className="flex-1 text-sm text-gray-700">{content}</div>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="p-1 -mr-1 rounded-full hover:bg-gray-100"
                            >
                                <X className="w-4 h-4 bg-gray-100 text-gray-500" />
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MobileTooltip;