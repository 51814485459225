import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery, useApolloClient } from '@apollo/client';
import { GET_CURRENT_USER, GET_USER_CATALOGS } from '../graphql/queries';
import { UPDATE_SELECTED_CATALOG, REFRESH_TOKEN } from '../graphql/mutations';
import { useCallback } from 'react';


const AuthContext = createContext();

export const AuthProvider = React.memo(({ children }) => {
    const [user, setUser] = useState(null);
    const [catalogs, setCatalogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCatalog, setSelectedCatalog] = useState(null);
    const client = useApolloClient();

    const [refreshTokenMutation] = useMutation(REFRESH_TOKEN);

    const { data: userData, loading: userLoading, error: userError, refetch: refetchUser } = useQuery(GET_CURRENT_USER, {
        skip: !localStorage.getItem('accessToken'),
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [authState, setAuthState] = useState(/* initial state */);
    const updateAuthContext = useCallback((updates) => {
        setAuthState(prevState => ({ ...prevState, ...updates }));
    }, []);

    const [getUserCatalogs, { loading: catalogsLoading, error: catalogsError, data: catalogsData }] = useLazyQuery(GET_USER_CATALOGS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [updateSelectedCatalogMutation] = useMutation(UPDATE_SELECTED_CATALOG);

    const refreshToken = useCallback(async () => {
        const token = localStorage.getItem('refreshToken');
        if (token) {
            try {
                const { data } = await refreshTokenMutation({ variables: { refreshToken: token } });
                localStorage.setItem('accessToken', data.refreshToken.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken.refreshToken);
                setUser(data.refreshToken.user);
                return true;
            } catch (error) {
                console.error('Failed to refresh token:', error);
                logout();
                return false;
            }
        }
        return false;
    }, [refreshTokenMutation]);

    useEffect(() => {
        const initializeAuth = async () => {
            setIsLoading(true);
            const tokenRefreshed = await refreshToken();
            if (!tokenRefreshed) {
                setIsLoading(false);
            }
        };

        initializeAuth();
    }, [refreshToken]);


    const updateSelectedCatalog = useCallback(async (catalogId) => {
        if (!user || !catalogId) return;
        try {
            const { data } = await updateSelectedCatalogMutation({
                variables: { userId: user.id, catalogId }
            });
            if (data.updateSelectedCatalog) {
                setUser(prevUser => ({
                    ...prevUser,
                    selectedCatalog: catalogs.find(cat => cat.id === catalogId)
                }));
                localStorage.setItem('selectedCatalogId', catalogId);

            }
        } catch (error) {
            console.error('Error updating selected catalog:', error);
        }
    }, [user, catalogs, updateSelectedCatalogMutation]);

    useEffect(() => {
        if (userData?.getCurrentUser) {
            setUser({
                ...userData.getCurrentUser,
                isVerified: userData.getCurrentUser.isVerified || false // Ensure isVerified is included
            });
            getUserCatalogs({ variables: { userId: userData.getCurrentUser.id } });
            setSelectedCatalog(userData.getCurrentUser.selectedCatalog);
            // console.log(userData.getCurrentUser)
            // console.log("selected catalog", userData.getCurrentUser.selectedCatalog)
            setIsLoading(false);
        }
    }, [userData, getUserCatalogs]);

    useEffect(() => {
        if (getUserCatalogs.data?.getUserWithCatalogs) {
            setCatalogs(getUserCatalogs.data.getUserWithCatalogs.catalogs);
        }
    }, [getUserCatalogs.data]);

    useEffect(() => {
        if (user && catalogs.length > 0 && !user.selectedCatalog) {
            const savedCatalogId = localStorage.getItem('selectedCatalogId');
            const catalogToSelect = savedCatalogId
                ? catalogs.find(cat => cat.id === savedCatalogId)
                : catalogs[0];

            if (catalogToSelect) {
                updateSelectedCatalog(catalogToSelect.id);
            }
        }
    }, [user, catalogs, updateSelectedCatalog]);

    const refetchUserData = useCallback(async () => {
        try {
            const { data } = await refetchUser();
            if (data && data.getCurrentUser) {
                setUser({
                    ...data.getCurrentUser,
                    isVerified: data.getCurrentUser.isVerified || false
                });
                await getUserCatalogs({ variables: { userId: data.getCurrentUser.id } });
            }
        } catch (error) {
            console.error('Error refetching user data:', error);
        }
    }, [refetchUser, getUserCatalogs]);

    const login = (userData) => {
        setUser(userData);
    };

    const logout = useCallback(() => {

        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getCurrentUser' });
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getUserWithCatalogs' });

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('selectedCatalogId');
        setUser(null);
        setCatalogs([]);
    }, []);



    return (
        <AuthContext.Provider value={{
            user,
            catalogs,
            login,
            logout,
            selectedCatalog,
            loading: isLoading || userLoading || catalogsLoading,
            error: userError || catalogsError,
            updateSelectedCatalog,
            refetchUserData,
            updateAuthContext,
            refetch: refetchUser
        }}>
            {children}
        </AuthContext.Provider>
    );
});


export const useAuth = () => useContext(AuthContext);